input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

body {
    background-color: #000;
    color: #fff;
    /* Text color */
    margin-left: 10%;
    margin-right: 10%;

}

.submitButton {
    background-color: #4CAF50;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    /* Rounded corners */
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #45a049;
    /* Darker green on hover */
}